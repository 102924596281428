import React from "react";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import { hourlyRate } from "../../components/Helpers";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import Img from "../../components/Img.js";
import H from "../../components/Headline";
import ProfileCard from "../../components/ProfileCard";
import ContactForm from "../../components/ContactForm";
import ImgScreenshot from "../../components/ImgScreenshot.js";
import { useAlternateLangs } from "../../components/Hreflangs";

const breadCrumbLevels = {
  Home: "/",
  "About me": "/en/about",
  "Google Tag Manager Consultant": "/en/google-tag-manager-consultant"
};

// Hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/google-tag-manager-consultant"
);

const GoogleTagManagerConsultant = props => (
  <Layout location={props.location} alternateLangs={alternateLangs}>

    <React.Fragment>
      <SEO
        title="Freelance Google Tag Manager Consultant: M. Kupperschmidt"
        description="Matthias Kupperschmidt is a freelance consultant and developer for Google Tag Manager. Read about his background, services and request a quote."
        lang="en"
        canonical="/en/google-tag-manager-consultant"
        image="tag-management-consultant-hero"
        alternateLangs={alternateLangs}
      />
      <MainContent article>
        <Img
          src="tag-manager-consultant/matthias-kupperschmidt-presenting-at-founders-house-oct-2019.jpg"
          alt="Google Tag Manager freelance consultant Matthias Kupperschmidt presenting at Founders House, October 2019"
        />
        <Breadcrumb breadCrumbLevels={breadCrumbLevels} />
        <H as="h1">Google Tag Manager Consultant</H>
        <ProfileCard
          tags={["Tracking Setup", "Retargeting", "Ecommerce Tracking", "Google Analytics", "Custom Event Tracking", "JavaScript", "React", "Python", "EN, DE, DA"]}
          profession="Google Tag Manager Consultant"
          rate={`${hourlyRate}€/hr`}
          location="remote from Copenhagen, DK"
          cta="Contact"
          alt="GTM expert"
        />
        <p>I am a Google Tag Manager consultant and I plan and implement <Link to="/en/tag-management">tag management systems</Link> for my clients.</p>
        <p>
          With tag management systems you can implement multiple third-party tags (e.g. Google Analytics, Facebook
          pixel, Google Ads) on your website quick and easy.
        </p>
        <p className="baseline">
          Tag management platforms became a super popular marketing technology during recent years. In fact, the global tag management system market is anticipated to continue <a href="https://www.businesswire.com/news/home/20191209005363/en/Global-Tag-Management-System-Market-Analysis-Report" target="">growing with an annual growth rate of 5.7% until 2024</a>.
        </p>

        <p className="baseline">
          Google Tag Manager (GTM) is the most widely used tag management
          solution with a <a href="https://trends.builtwith.com/analytics/tag-management/traffic/Entire-Internet" target="_blank">market share of 94% in the tag manager market</a> and <b>free</b> in its standard version.
        </p>
        <p>
          Through GTM, I deploy tracking setups integrated with third-party tags that <b>scale over multiple websites</b> and
          languages.
        </p>
        <p>
          It enables you to use user interactions from your Google Analytics account to create user
          segments for your marketing efforts, like ad campaigns on Facebook Ads, Doubleclick or Linkedin.
        </p>
        <br />
        <br />
        <H as="h6" style={{ "textAlign": "center" }}>Who I've worked with</H>
        <ImgScreenshot
          src="about/clients_700px.png"
          alt="clients I worked with"
          className="article-img"
        />
        <br />
        <br />
        <br />


        <H as="h2" style={{ "textAlign": "center" }}>Get a Quote</H>
        <ContactForm showHeadline={false} formName="ga consultant (EN) - contact form" />

        <H as="h2">Services</H>
        <p>
          As a freelance Google Tag Manager consultant, I offer the same services (or more) as a typical digital
          marketing agency. You save time and budget since the communication is directly with the Google Tag Manager expert. No
          project management and administration overhead cost.
        </p>
        <p>Here a short list of my <b>Google Tag Manager consulting services</b>:</p>
        <ul>
          <li>Google Tag Manager installation</li>
          <li>cookie banners</li>
          <li><Link to="/en/event-tracking">event tracking</Link></li>
          <li>
            <Link to="/en/google-analytics-setup">Google Analytics implementation</Link>
          </li>
          <li>campaign coordination with media and web agencies</li>
          <li>enhanced eCommerce tracking</li>
          <li>
            <Link to="/en/data-layer#data-layer-implementation">data layer implementation</Link>
          </li>
          <li>data integration across tags</li>
          <li>retargeting with e.g. Facebook, Google Ads, Twitter, Linkedin</li>
          <li>goal conversion tracking</li>
          <li>affiliate tracking</li>
          <li>campaign tracking</li>
          <li>video tracking</li>
          <li>Data import and export</li>
          <li>debugging and troubleshooting</li>
          <li>custom tag solution with JavaScript</li>
        </ul>
        <p>
          Any tagging solution can be deployed and changed quickly via the <b>GTM container</b> or any other tag
          management solution. GTM evolved as a standard for managing data, marketing tags, analytics and custom tag implementations primarily because a GTM account is free and works reliably.
        </p>
        <p>
          Since its release, marketers can finally execute and adjust their digital campaign strategy very flexibly
          since no changes in the source code are required.
        </p>
        <H as="h2">What is Google Tag Manager?</H>
        <p>
          Google Tag Manager is a tool to implement and configure tags on a website. You can define rules for the
          execution of each code snippet and define exactly which data it has access to.
        </p>
        <p>
          Everything is done from the user-friendly Google Tag Manager interface, which delivers a quick and snappy
          developer experience. Implementing new tags through a graphical user interface (GUI) in itself is a welcome
          new experience, because it requires less concentration and saves implementation time.
        </p>
        <p>
          <b>Google Tag Manager is essentially a <Link to="/en/wiki-analytics/container-tag">container tag</Link> through which you can implement all other marketing tags on your
            website</b>. Only the Google Tag Manager code snippet goes into the source code and the remaining code snippets can be implemented
          and configured at scale from the Google Tag Manager account.
        </p>
        <p>You can even test any new tag with it's preview mode to ensure everything works as intended before you send real data to your analytics tool.</p>
        <p className="baseline">
          Through its flexibility, it grew very popular for online marketing and data management, especially with
          Google products. It is so popular, that it is <a href="https://w3techs.com/technologies/overview/tag_manager" target="_blank">used by 30.4% of websites</a> on the web, <a href="https://trends.builtwith.com/widgets/Google-Tag-Manager" target="_blank">counting 4.9 million live websites using GTM</a>.  Google Analytics tags that share data with advertising services (e.g. Facebook, Google Ads)
          are typical use cases.
        </p>
        <p>
          Read a more detailed{" "}
          explanation of <Link to="/en/what-is-google-tag-manager">what Google Tag Manager is</Link> and see some
          screenshots of the Google Tag Manager code and its interface if you want to know more.
        </p>
        <p>
          If you want to learn how to use Google Tag Manager with Google Analytics, read my{" "}
          guide on <Link to="/en/google-analytics-setup">how to implement a Google Analytics setup with GTM</Link> and
          also <Link to="/en/event-tracking">how to set up event tracking with GTM</Link>.
        </p>

        <H as="h2">What are the benefits of Google Tag Manager?</H>
        <p>
          The main benefit of Google Tag Manager is the quick and flexible deployment of multiple tags and the scalable data
          management among them.
        </p>
        <p>
          These comparably small implementation changes cost time and add up for clients that run many digital
          campaigns. Good programmers are expensive and agencies have limited resources. Therefore it makes sense to
          outsource tag implementation tasks to a tool with a graphical user interface (GUI) and faster deployment
          time.
        </p>
        <p>
          Read more of an{" "}
          <Link to="/en/google-tag-manager-benefits">in-depth list of the Google Tag Manager benefits</Link>, to know
          more.
        </p>

        <H as="h2">Why do you need Google Tag Manager consulting?</H>
        <p>
          A GTM consultant plans the Google Analytics implementation and makes sure that other marketing services get
          the right data about e.g. campaign visits and conversions to optimize your segments.
        </p>
        <p>
          If you run many campaigns with individual landing pages, a GTM specialist will get conversion tracking added
          to the analytics reports fast.
        </p>
        <p>
          By tracking the events on your landing page, you can check the ongoing performance of your campaigns. If the
          campaign doesn't perform as expected, you will notice it early on and can re-adjust. This feedback-cycle for
          each campaign brings along learnings to optimize future campaigns.
        </p>

        <p>
          A Google Tag Manager expert also acts as a developer in your marketing team and helps with tagging,
          JavaScript tasks and custom solutions around your site.
        </p>
        <p>
          GTM consulting is increasingly important for clients that rely on other digital marketing services for their
          business. It maximizes the value of each page view with high quality engagement data about SEO, email and
          retargeting campaigns to help you optimize your ad spend.
        </p>

        <H as="h2">How much does GTM Consulting cost?</H>
        <p className="baseline">
          My freelance rate is <b>{hourlyRate}€</b> per hour net. If I am to plan a Google Analytics implementation
          for a client, I estimate the scope of work and send a proposal outlining all deliverables. The scope is
          dependent on the size of the website and the amount of KPIs that should be tracked.
        </p>
        <p className="baseline">
          The website's underlying technology also plays a role in terms of ease of implementation. Google Analytics
          setups with event tracking and 3rd-party data integration cost between <b>1800€</b> and <b>6000€</b> net.
        </p>
        <p>
          If you need continuous Google Tag Manager help for a larger site, a monthly retainer budget according to the
          expected workload can be agreed upon. Monthly retainers should account for a minimum of 10hrs per month.
        </p>
        <p className="baseline">
          Smaller tasks are charged with a flat fee between <b>100€</b> and <b>300€</b>.
        </p>
        <H as="h2">My profile</H>
        <p>
          I built my first website when I was 14 years old, to trade movies with my classmates. With 18, I started
          running an e-commerce shop for table soccer called "Kickerkult". When I got more into Analytics tools, SEO
          and ran my first newsletters, I was hooked with the web. Since then, I wanted to focus my career on the
          internet.{" "}
        </p>
        <p>
          I worked in digital agencies since 2011 and focused solely on SEO and Analytics since 2015. I built tag
          management systems with GTM for e-commerce sites, supermarket chains, car manufacturers, restaurant chains,
          insurance companies and many other sectors. My clients tend to be enterprises, large companies or they
          somewhat reflect a similar organization and procedures internally.
        </p>
        <p>
          I work self-employed as a Google Tag Manager freelance consultant since 2018. I also do search engine
          optimization and <Link to="/en/analytics-consulting">analytics consulting</Link>. All out of one hand.
        </p>
        <p>
          My personality is analytical, rational and pragmatic. I like analysis to find the right solution, but also
          don't want to have things complicated. Hence, me stepping out of the hectic agency to work for myself as a
          GTM freelancer was a logical consequence.
        </p>
        <p>
          To keep my know-how up to date with tag management, analytics and SEO, I run a few websites and visit
          conferences. I also co-organize the Copenhagen SEO-Meetup.
        </p>

        <p>
          You can read more about my background on {" "}
          <a href="https://www.linkedin.com/in/matthiaskupperschmidt">Linkedin</a> or find more information on my{" "}
          <Link to="/en/about">about page</Link>.
        </p>
        <H as="h2">Looking for a freelance Google Tag Manager specialist?</H>
        <p>
          Reach out if you're looking for somebody to help you with a Google Tag Manager implementation. I can consult
          you on what events to track and what indicators to look at to optimize your conversions.
        </p>
        <p>
          Request a quote through the <Link to="/en/contact">contact page</Link> and I will get back with a proposal.
        </p>
        {/* <RelatedContent /> */}
      </MainContent>
    </React.Fragment>

  </Layout>
);

export default GoogleTagManagerConsultant;
